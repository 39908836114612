/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import PageBottomSidebarModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageBottomSidebarModules.jsx";
import PageContentAreaBottomModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageContentAreaBottomModules.jsx";
import PageContentAreaModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageContentAreaModules.jsx";
import PageRightSidebarModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageRightSidebarModules.jsx";
import PageTopSidebarModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageTopSidebarModules.jsx";
import usePageModules from "@municipio/gatsby-theme-basic/src/wsui/usePageModules.js";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import {
  PageContent,
  PageHeading,
  PagePreamble,
  PageBreadcrumbs,
  PageFeaturedImage,
  Seo,
} from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import {
  Section,
  PageGrid,
  PageGridItem,
  useThemeProps,
  PageSection,
} from "@wsui/base";
import { omit } from "lodash/fp";
import { Fragment } from "react";

import EventPageHeading from "../../../../EventPageHeading.jsx";
import EventPageHero from "../../../../EventPageHero.jsx";

export default function EventTemplate(props) {
  const theme = useTheme();
  props = useThemeProps({ props, name: "EventTemplate" });
  props = useThemeProps({ props, name: "Template" });
  let {
    defaultColspan = 7,
    hideTitle = null,
    contentSpacing = [5, 10],
    heroColor = "seed.20",
    footerMargin = 0,
  } = omit(["spacing"], props);
  const { title, content, isFrontPage, pageAppearance } = usePageContext();
  let hasSidebar =
    usePageModules("rightSidebar", { ignoreBackgrounds: true })?.length > 0;
  let topSidebarModules = usePageModules("topSidebar");
  let bottomSidebarModules = usePageModules("bottomSidebar");
  let hasTopSidebarModules = topSidebarModules?.length > 0;
  let hasBottomSidebarModules = bottomSidebarModules?.length > 0;
  let hasContentAreaModules =
    usePageModules("contentArea", { ignoreBackgrounds: true })?.length > 0;
  let hasContentAreaBottomModules =
    usePageModules("contentAreaBottom", { ignoreBackgrounds: true })?.length >
    0;

  hideTitle ??= pageAppearance?.hideTitle ?? hasTopSidebarModules;

  return (
    <article
      css={css`
        padding-bottom: ${!hasBottomSidebarModules && footerMargin
          ? theme.getLength(footerMargin)
          : null};
      `}
    >
      <Seo title={title} isFrontPage={isFrontPage} />

      <PageBreadcrumbs
        background={
          topSidebarModules?.[0] ? topSidebarModules[0].background : undefined
        }
      />

      <Section>
        <PageTopSidebarModules promoteFirstHeading={hideTitle} />
      </Section>

      <PageSection background={"transparent"}>
        <PageGrid>
          <PageGridItem colspan={defaultColspan}>
            <EventPageHeading
              hideTitle={hideTitle}
              background="transparent"
              colspan={defaultColspan}
              marginAfter
            />
            <PagePreamble />
          </PageGridItem>
        </PageGrid>
      </PageSection>

      <EventPageHero colspan={defaultColspan} color={heroColor} />

      {hasSidebar
        ? !!(
            content ||
            hasContentAreaModules ||
            hasContentAreaBottomModules
          ) && (
            <PageSection background="transparent">
              <PageGrid>
                <PageGridItem colspan={defaultColspan}>
                  <div
                    css={css`
                      ${theme.styleUtils.negateMarginStart}
                      ${theme.styleUtils.negateMarginEnd}
                    `}
                  >
                    {!!(content || !hideTitle) && (
                      <PageSection
                        background="transparent"
                        spacing={contentSpacing}
                      >
                        <PageHeading marginAfter hideTitle={hideTitle} />
                        <Section>
                          {/* <PageFeaturedImage /> */}
                          <PageContent />
                        </Section>
                      </PageSection>
                    )}
                    <Section>
                      <PageContentAreaModules
                        ignoreBackgrounds
                        maxColspan={defaultColspan}
                        gap={contentSpacing}
                      />
                      <PageContentAreaBottomModules
                        ignoreBackgrounds
                        gap={contentSpacing}
                      />
                    </Section>
                  </div>
                </PageGridItem>
                <PageGridItem colspan={5}>
                  <Section>
                    <PageRightSidebarModules
                      ignoreBackgrounds
                      gap={contentSpacing}
                      css={css`
                        ${theme.styleUtils.negateMarginStart}
                        ${theme.styleUtils.negateMarginEnd}
                      `}
                    />
                  </Section>
                </PageGridItem>
              </PageGrid>
            </PageSection>
          )
        : !!(
            content ||
            hasContentAreaModules ||
            hasContentAreaBottomModules
          ) && (
            <Fragment>
              {!!content && (
                <Fragment>
                  {/* <PageSection background="transparent">
                    <PageGrid>
                      <PageGridItem colspan={defaultColspan}>
                        <PageHeading hideTitle={hideTitle} />
                      </PageGridItem>
                    </PageGrid>
                  </PageSection> */}
                  {/* <PageFeaturedImage /> */}
                  <PageSection background="transparent">
                    <PageGrid>
                      <PageGridItem colspan={defaultColspan}>
                        <Section>
                          <PageContent />
                        </Section>
                      </PageGridItem>
                    </PageGrid>
                  </PageSection>
                </Fragment>
              )}
              <Section>
                <PageContentAreaModules maxColspan={defaultColspan} />
                <PageContentAreaBottomModules />
              </Section>
            </Fragment>
          )}

      <Section>
        <PageBottomSidebarModules />
      </Section>
    </article>
  );
}
