/** @jsx jsx */
import { jsx, useTheme } from "@emotion/react";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import { Html } from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import { PageGrid, PageGridItem, useThemeProps, PageSection } from "@wsui/base";
import { EventHero } from "@wsui/municipio";

const EventPageHero = (props) => {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "EventPageHero" });
  let { colspan, background = "transparent", ...restProps } = props;
  const { eventProperties, displaySettings, featuredImage } = usePageContext();
  const { eventOccasions = [], ...attributes } = eventProperties ?? {};

  return (
    <PageSection background={background}>
      <PageGrid>
        <PageGridItem colspan={colspan}>
          <EventHero
            occasions={eventOccasions}
            attributes={attributes}
            image={
              displaySettings?.postSingleShowFeaturedImage !== false &&
              featuredImage?.node
            }
            renderAttributeValue={(value) => <Html>{value}</Html>}
            {...restProps}
          />
        </PageGridItem>
      </PageGrid>
    </PageSection>
  );
};

export default EventPageHero;
